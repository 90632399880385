export type ProjectProps = {
    name: string,
    details: string[],
    link?: string
}

export const Project = (props: ProjectProps) => {
    return (
        <div>
            <p className='font-bold text-lg text-black'>{props.name}</p>
            {props.link !== undefined ? <a className="text-blue-500 underline" href={props.link}>{props.link}</a> : null}
            {props.details?.map(detail => {
                return (
                    <li className='text-md text-black'>{detail}</li>
                )
            })}
        </div>
    )
}