
export type EducationProps = {
    university: string,
    graduationYear: number
    degree: string,
    minor: string,
}

export const Education = (props: EducationProps) => {
    return (
        <div>
            <p className='text-black font-bold text-lg'>{props.university}</p>
            <p className='text-black text-md'>{props.degree} {props.graduationYear}</p>
            <p className='text-black text-md'>{props.minor}</p>
        </div>
    )
}