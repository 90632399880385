
export type ExperienceProps = {
    company: string,
    tenure?: string
    title: string,
    details: string[]
}

export const Experience = (props: ExperienceProps) => {
    return (
        <div className='mb-2'>
            <p className='text-black font-bold text-lg'>{props.company}</p>
            <p className='text-black text-md'>{props.tenure}</p>
            <p className='text-black text-md'>{props.title}</p>
            <div className="mt-3">
                {props.details?.map(detail => {
                    return (
                        <li className='text-black text-md mb-1'>{detail}</li>
                    )
                })}
            </div>
        </div>
    )
}