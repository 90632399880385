import { Education } from "./components/Education";
import { Experience } from "./components/Experience";
import { Project } from "./components/Project";

function App() {
  return (
    <div className='flex flex-col p-10 gap-5'>
      <div className='flex flex-col gap-1'>
        <div className='flex gap-2'>
          <div>
            <img height={100} width={100} src="https://s3.amazonaws.com/claytonphenderson.com/clayton.jpeg" alt='its me'/>
          </div>
          <div className="flex flex-col">
            <h1 className='text-3xl font-bold text-charcoal'>Clayton Henderson</h1>
            <a href="mailto:claytonphenderson@gmail.com" className="text-lg text-blue-500 underline">claytonphenderson@gmail.com</a>
            <a href="https://github.com/claytonphenderson" className="text-lg text-blue-500 underline">GitHub</a>
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-10 mt-5 md:flex-row'>
        <div className='flex flex-col w-full md:w-1/2 gap-10'>
          <div>
            <div className='flex'>
              <h1 className='flex font-bold bg-black text-white p-2 text-lg mb-2'>Experience</h1>
            </div>
            <div className='flex flex-col gap-2'>
              <Experience company={'weavix'}
                tenure={'October 2020 - Present'}
                title={'Senior Software Development Lead'}
                details={[
                  'Technical team lead in the development of custom telecommunication systems for non desk-bound workers, primarily in manufacturing and construction settings.',
                  'Developed several mission-critical features for the weavix walt handset, a custom-built hardware device that disrupts the traditional radio “walkie-talkie” model.',
                  'Responsible for hands-on development while also guiding teammates, presenting milestone updates to internal and external stakeholders, and designing technical solutions around business goals.',
                  'Some projects include: location services based on bluetooth beacon trilateration and signal sampling with fingerprinting, multi-network broadcasting to mitigate potential loss of transmission, and integrations with cutting-edge AI models for translation, translation, and image recognition.',
                ]} />

              <Experience company={'Service Management Group'}
                tenure={'April 2019 - March 2020 & May 2021 - October 2022'}
                title={'Senior Software Engineer'}
                details={[
                  'Developed customer feedback collection services used by some of the largest restaurant chains in the United States and Europe, including McDonalds, Chipotle, and Yum! Brands.',
                  'Specialized in building high-performance RESTful APIs as well as client-facing reporting tools to view aggregated reports, facilitate survey editing, and track ongoing customer issues.',
                  'Some projects include: custom built map-based reports without the use of a third-party map provider, and stream text analytics pipelines to analyze customer feedback for sentiment and markers of abuse.'
                ]} />

              <Experience company={'27Global'}
                tenure={'March 2020 - May 2021'}
                title={'Senior Software Engineer'}
                details={[
                  'Collaborated with organizations of all sizes to build custom solutions for a variety of business needs including an authentication provider for municipal utility services, logistics web portals for grain shippers, and the first product iterations of a startup aiding victims of domestic abuse.',
                  'Architecture lead for several customer engagements, overseeing development efforts for multiple SCRUM teams, ensuring high standards of performance and quality.'
                ]} />

              <Experience company={'Spreetail'}
                tenure={'May 2017 - April 2019'}
                title={'Software Engineer and Team Lead'}
                details={[
                  'Developed internal applications for the Customer Support team servicing our ecommerce customers. These tools tracked and managed customer contacts with a focus on efficiency, implementing a ‘treadmill’ process which routed customer communications to the agents most prepared to assist them.',
                  'Designed a listing management tool used by in-house copywriters to create the listing content used for over 10,000 product listings across Amazon, Ebay, Walmart.com, and Newegg.',
                  'These efforts contributed to the business growing from $300M to nearly $1B in revenue, and from 200 employees to over 500.'
                ]} />
              
              <Experience company={'opendorse'}
                title={'Intern'}
                tenure={'2014 - 2017'}
                details={[
                  'Proud to have been a part of the startup culture in Lincoln, NE throughout college.  Contributed to the first iterations of what would become the online marketplace for NIL and sponsorship deals for student and professional athletes.',
                  'I owe these people a debt of gratitude for their generosity, kindness, and invaluable mentorship.'
                ]} />
            </div>
          </div>
        </div>
        <div className='flex flex-col w-full md:w-1/2 gap-10'>
          <div>
            <div className='flex'>
              <h1 className='flex font-bold bg-black text-white p-2 text-lg mb-2'>Projects</h1>
            </div>
            <div className='flex flex-col gap-2'>
              <Project 
              name={'My WellChat'} 
              link='https://apps.apple.com/us/app/my-wellchat/id6476068241'
              details={[
                'A mobile app built for Annie Eller RD LLC - a dietician consulting business. Enables the dietician to send direct messages, track progress toward health goals, and provide immediate feedback and support to their clients.'
              ]}/>
              <Project 
              name={'Slurp'} 
              link='https://github.com/claytonphenderson/slurp'
              details={[
                'A toy telemetry and event collection tool, built in an effort to avoid high third-party service costs as well as start learning a new programming language.'
              ]}/>
            </div>
          </div>
          <div>
            <div className='flex'>
              <h1 className='flex font-bold bg-black text-white p-2 text-lg mb-2'>Education</h1>
            </div>
            <div className='flex flex-col gap-2'>
              <Education university={'University of Nebraska-Lincoln'} 
              graduationYear={2017} 
              degree={'Bachelor of Arts in Computer Science'} 
              minor={'Minor in Mathematics'}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
